<template>
  <!-- 内容 -->
  <div class="help_content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goBackHome()">{{ '帮助中心' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item v-if="routePath == 'last'" style="cursor: pointer !important;"><span style="color: #AB54DB;" @click="goBackLast()">{{ '上一页' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">常见问题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice_list" style="margin-top: 30px">
      <div id="problem0">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">{{ $t('problem.must_read_newbies') }}</span>
          <img style="display: inline-block; width: 16px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/remind.png">
        </div>
        <div style="display: block;margin-top: 6px;">
          <span style="font-size: 14px;">{{$t('problem.must_read_newbies_content1_1')}}</span>
          <span style="font-size: 14px; color: #FF4D4F">{{$t('problem.must_read_newbies_content1_2')}}</span>
          <span style="font-size: 14px;">{{$t('problem.must_read_newbies_content1_3')}}</span>
        </div>
<!--        <span style="font-size: 14px;">{{$t('problem.must_read_newbies_content1')}}</span>-->
        <div style="margin-top: 6px">
          <span style="font-size: 14px; color: #FF4D4F">{{$t('problem.must_read_newbies_content2_0')}}</span>
          <span style="font-size: 14px;">{{$t('problem.must_read_newbies_content2')}}</span>
        </div>
        <span style="font-size: 14px;display: block;margin-top: 6px">{{$t('problem.must_read_newbies_content3')}}</span>
      </div>
      <div id="problem1" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">UPlus产品介绍</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">我们的产品就属于量化策略交易，全自动交易，不需要设置，不需要手动开平仓，将交易所API绑定到我们平台，授权交易权限就可以了。本金收益全在交易所，安全无风险。软件免费使用，收益时扣取利润分成，扣取每笔盈利的25%！我们没有直接扣取利润的权限，所以需要会员提前在APP充值一些USDT。充值的USDT可以用来充当燃料或是购买点卡。</span>
      </div>

      <div id="problem2" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">专属用户介绍</span>
<!--        <span style="font-size: 14px;display: block;margin-top: 6px">Okx专属用户：通过OKX专属链接（https://www.lybg1688.com/join/UPLUS）或邀请码（UPLUS）注册的交易所会员。<br>币安专属用户：申请币安API，通过UPlus平台校验并绑定成功的用户。<br>专属用户权益：专属用户享受限时98%燃料费折扣。</span>-->
        <span style="font-size: 14px;display: block;margin-top: 6px">Okx专属用户：通过OKX专属链接（{{ $registered_address.okx_address() }}）或邀请码（UPLUS3）注册的交易所会员。<br>币安专属用户：申请币安API，通过UPlus平台校验并绑定成功的用户。<br>专属用户权益：专属用户享受限时98%燃料费折扣。</span>
      </div>

      <div id="problem3" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">启动机器人需要什么条件</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">燃料或是点卡必须要大于等于本金的10%才可以启动机器人。</span>
      </div>

      <div id="problem4" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">策略选择说明</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">{{ $t('problem.Strategy_Instructions_tip') }}</span>
      </div>

      <div id="problem5" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">合约策略交易概括说明</span>
<!--        <span style="font-size: 14px;display: block;margin-top: 6px">交易策略简单来理解属于多空对冲，利用区间波动以及多种算法，计算得出不同交易对的最优参数，来把握买进及卖出机会从而实现套利。</span>-->
        <span style="font-size: 14px;display: block;margin-top: 6px">{{ $t('problem.Contract_Trading_Instructions_tip') }}</span>
      </div>

      <div id="problem12" style="margin-top: 20px">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">合约策略浮亏说明</span>
          <img style="display: inline-block; width: 16px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/remind.png">
        </div>
        <span style="font-size: 14px;display: block;margin-top: 6px">合约策略2.9.5版本及闪电版都优化了交易逻辑，为了抵抗风险增加了组合订单，初始会亏损平一部分，到后期会补齐亏损后盈利结束，过程中也会导致交易账户浮亏较大，初期盈利抵不过浮亏，所以机器人需要长期使用，不能随意操作、关停。</span>
      </div>

      <div id="problem6" style="margin-top: 20px">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">修改策略仓位说明</span>
          <img style="display: inline-block; width: 13px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/hot.png">
        </div>
        <span style="font-size: 14px;display: block;margin-top: 6px">当前修改仓位只可以增加不能减少。在当前策略找到修改策略，点击修改策略，输入新的USDT金额，点击确认修改即可！。修改后新仓位可能不会立即生效，而是保证策略正常运行的情况下逐步更新完成。</span>
      </div>

      <div id="problem7" style="margin-top: 20px">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">止损说明</span>
          <img style="display: inline-block; width: 13px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/hot.png">
        </div>
        <span style="display: block; font-weight: 600;margin-top: 6px;font-size: 14px;">策略设置不止损：</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">想要扛单至行情回调可选择不止损。策略使用的是全仓保证金，可能会亏掉交易账户仓位设置以外的保证金，需留意风险。</span>
        <span style="display: block; font-weight: 600;margin-top: 6px;font-size: 14px;">策略设置止损：</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">1.策略风险较高时会平掉部分仓位以释放保证金抵御风险，平掉仓位的浮亏会转为实亏。后续盈利优先补亏损，补亏损不消耗燃料费及点卡；<br>2.机器人会实时监控策略的风险水平，当风险较高时会自动止损，可能会触发多次止损，最大止损总仓位的75%。</span>
      </div>

      <div id="problem8" style="margin-top: 20px">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">扣费说明</span>
          <img style="display: inline-block; width: 13px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/hot.png">
        </div>
        <span style="font-size: 14px;display: block;margin-top: 6px">软件免费使用，收益时扣取利润分成，扣取每笔盈利的25%！我们没有直接扣取利润的权限，所以需要会员提前在APP充值一些USDT。充值的USDT可以用来充当燃料或是购买点卡。点卡跟燃料都是用来扣取利润分成的！</span>
      </div>

      <div id="problem9" style="margin-top: 20px">
        <div>
          <span style="display: inline-block; font-weight: bold; vertical-align: middle">点卡和燃料费说明</span>
          <img style="display: inline-block; width: 13px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/hot.png">
        </div>
        <span style="font-size: 14px;display: block;margin-top: 6px">点卡跟燃料都是用来扣取利润分成的。收益时扣取点卡或是燃料费来抵扣利润。区别在于购买点卡时有折扣，但如果有剩余是不能提走，而燃料没有折扣有剩余可以提走!</span>
      </div>

<!--      <div id="problem10" style="margin-top: 20px">-->
<!--        <div>-->
<!--          <span style="display: inline-block; font-weight: bold; vertical-align: middle">交易账户浮亏说明</span>-->
<!--          <img style="display: inline-block; width: 13px; height: 16px; vertical-align: middle;margin-left: 10px" src="../../assets/img/help/hot.png">-->
<!--        </div>-->
<!--        <span style="font-size: 14px;display: block;margin-top: 6px">{{ $t('problem.Trading_account_loss_tip') }}</span>-->
<!--      </div>-->

      <div id="problem11" style="margin-top: 20px">
        <span style="display: block; font-weight: bold">提现说明</span>
        <span style="font-size: 14px;display: block;margin-top: 6px">{{ $t('problem.Withdrawal_Instructions_tip') }}</span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "help_content_one",
  components: {},
  data(){
    return{
      noticeContent: {},
      id: '',
      routePath: '',
      problemId: ''
    }
  },

  created() {

  },

  mounted() {

  },

  activated() {
    this.$nextTick(()=>{
      this.routePath = this.$route.query.routePath
      this.problemId = this.$route.query.problemId
      if (this.problemId){
        document.querySelector('#'+this.problemId).scrollIntoView({
          behavior: "smooth",
          // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "center",
          // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest"
          // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })
        document.getElementById(this.problemId).className = 'div_flash'
        setTimeout(()=>{
          document.getElementById(this.problemId).className = 'div_flash_no'
        },1000)
      }
    })
  },

  methods: {
    goBackHome(){
      if (this.routePath == 'last'){
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
    goBackLast(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
span{
  color: #1E2329;
}

.div_flash{
  box-shadow: 0px 0px 33px rgba(34, 34, 44, 0.6);
  border-radius: 3px;
  animation-duration: 2s;
  padding: 10px;
}

.div_flash_no{
  box-shadow: unset;
  padding: 0;
  transition-duration: 1s
}

@media screen and (max-width: 900px){
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }
  .content_list {
    column-count: 1;
  }
  .content_list span{
    color: #464255;
    font-size: 14px;
  }
  .content_title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    display: block;
  }
  .auto_list{
    column-count: 3;
    text-align: center;
  }
  .notice_list{
    margin-bottom: 30px;
  }

  .el-container{
    display: block;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 90%;
    height: 230px;
    text-align: center;
  }

  .help_content_top_left{
    margin-top: 20px;
  }
  .help_content_bg{
    text-align: center;
  }
}
@media screen and (min-width: 900px){
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    color: #464255;
    width: 30%;
  }

  .auto_list{
    column-count: 6;
    text-align: center;
  }

  .notice_list{
    margin-bottom: 30px;
  }

  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 14px;
  }

  .help_content {
    width: 1200px;
    min-height: 795px;
    margin: 50px auto 0;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 380px;
    height: 260px;
    margin-right: 30px;
  }

  .help_content_top_left{
    margin-left: 30px;
  }

  .help_content_bg{
    text-align: unset;
  }
}
</style>
